import {useCallback} from 'react';
// auth provider
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { NoEthereumProviderError, UserRejectedRequestError as UserRejectedRequestErrorInjected } from '@web3-react/injected-connector'
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect, WalletConnectConnector } from '@web3-react/walletconnect-connector'
import {setupNetwork} from "../utils/setupNetwork";
import { ConnectorNames, connectorsByName } from '../utils/web3React'
import {connectorLocalStorageKey} from "../config/context";
import {showError} from "../utils/pageHelpers";
import useActiveWeb3React from './useWeb3';

const useAuth = () => {
    const { activate, deactivate } = useWeb3React()
    const { library }  = useActiveWeb3React()

    // console.log(library.getProvide)

    // const provider = new ethers.providers.Web3Provider(library)
    const login = useCallback(
        (connectorID) => {
            const connector = connectorsByName(connectorID)
            if (connector) {
                console.log(connector)
                try {
                    activate(connector, async (error) => {
                        // showError(error)
                        console.log(error instanceof UnsupportedChainIdError)
                        if (error instanceof UnsupportedChainIdError) {
                            const hasSetup = await setupNetwork({library})
                            if (hasSetup) {
                                activate(connector)
                                window.localStorage.setItem(connectorLocalStorageKey, connectorID);
                            }
                        } else {
                            window.localStorage.removeItem(connectorLocalStorageKey)

                            if (error instanceof NoEthereumProviderError) {
                                showError('Provider Error - No provider was found')
                            } else if (
                                error instanceof UserRejectedRequestErrorInjected ||
                                error instanceof UserRejectedRequestErrorWalletConnect
                            ) {
                                if (connector instanceof WalletConnectConnector) {
                                    const walletConnector = connector
                                    walletConnector.walletConnectProvider = null
                                }
                                showError('Authorization Error - Please authorize to access your account')
                            } else {
                                showError(error.name, error.message)
                            }
                        }
                        console.log(error)
                        // showError(error)
                    }).then(r => console.log(`Result ${r}`))
                    window.localStorage.setItem(connectorLocalStorageKey, connectorID);
                }catch (e) {
                    console.log(e)
                    showError(e)
                }
            } else {
                showError('Unable to find connector - The connector config is wrong')
            }
        },
        [activate]
    )

    const logout = useCallback(() => {
        deactivate()
        // This localStorage key is set by @web3-react/walletconnect-connector
        if (window.localStorage.getItem('walletconnect')) {
            connectorsByName(ConnectorNames.WalletConnect).close()
            connectorsByName(ConnectorNames.WalletConnect).walletConnectProvider = null
        }
        window.localStorage.removeItem(connectorLocalStorageKey)
    }, [deactivate])

    return { login, logout }
};

export default useAuth;
