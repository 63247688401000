import { OxNFTContract, graphURL } from '../config';
import abi from '../config/abis/AeromatiX.json';
import axios from 'axios';
import { ethers } from 'ethers'

const API_KEY = 'P8QDBYHPIPBT6BRUZN6PXXKUQXC91WJFNH'; // Replace with your own Etherscan API key

function getDate(x) {
    const myDate = new Date(x * 1000);
    return myDate;
}


async function getLast10TransactionsAndEvents(library, userId, slot) {

    try {

        const provider = library
      
          const options = userId ? {
              method: 'POST',
              url: graphURL,
              headers: {
                  'content-type': 'application/json'
              },
              data: {
                  query: `{
                      treePayoutEvents(orderBy: blockTimestamp, orderDirection: desc, where: {to: ${userId}, level: ${slot}}) {
                          id
                          from
                          amount
                          blockNumber
                          transactionHash
                      }
      
                      directPaidEvents(orderBy: blockTimestamp, orderDirection: desc, where: {to: ${userId}, level: ${slot}}) {
                          id
                          from
                          amount
                          blockNumber
                          transactionHash
                      }
                  }`
              }
          } : {
            method: 'POST',
            url: graphURL,
            headers: {
                'content-type': 'application/json'
            },
            data: {
                query: `{
                    treePayoutEvents(orderBy: blockTimestamp, orderDirection: desc) {
                        id
                        from
                        amount
                        blockNumber
                        transactionHash
                    }
    
                    directPaidEvents(orderBy: blockTimestamp, orderDirection: desc) {
                        id
                        from
                        amount
                        blockNumber
                        transactionHash
                    }
                }`
            }
        };
      
          const response = await axios.request(options);
        //   console.debug(response)
          const res = response.data;
      
          const treePayoutEvents = res.data.treePayoutEvents || [];
          const directPaidEvents = res.data.directPaidEvents || [];
      
          const allEvents = [...treePayoutEvents, ...directPaidEvents];
          const sortByTimestamp = allEvents.sort((a, b) => b.blockTimestamp - a.blockTimestamp);
      
          const last10Transactions = sortByTimestamp.slice(0, 100);
        //   console.debug("transactions", last10Transactions)
          let activities = [];
          let handleID = [];
          for (const tx of last10Transactions) {
          
          const receipt = await provider.getTransactionReceipt(tx.transactionHash);
          const inter = new ethers.utils.Interface(abi);
          if (receipt.logs.length > 0) {
              for(let j = 0; j < receipt.logs.length; j++)
              {
                  let data = {};
                  try{
                      const events =  inter.parseLog(receipt.logs[j]);
                      if(events.name == 'Registration')
                      {
                          data.txId = tx.transactionHash;
                          data.blockNumber = tx.blockNumber;
                          data.eventsName = events.name;
                          data.userId = events.args.newUserId;
                          data.referrerId = events.args.orignalRefId;
                          const date = getDate(events.args.time);
                          data.date = date.toDateString();
                          data.amount = tx.amount;
                          const key = `${data.txId}-${parseInt(data.userId)}`;
                          if(handleID.indexOf(key) == -1){
                              activities.push(data);
                            }
                            handleID.push(key)
                      }else if(events.name == "TreePayout")
                      {
                          data.txId = tx.transactionHash;
                          data.blockNumber = tx.blockNumber;
                          data.eventsName = events.name;
                          data.userId = events.args.receiverId;
                          data.fromuserId = tx.from;
                          data.amount = events.args.amount.toString();
                          const date = getDate(events.args.time);
                          data.date = date.toDateString();
                          const key = `${data.txId}-${parseInt(data.userId)}`;
                          if(handleID.indexOf(key) == -1){
                            activities.push(data);
                          }
                            handleID.push(key)
                      }
                  }catch(err)
                  {
                      // console.log("newerr", err);
                      // console.log(receipt.logs.length)
                  }
              }
            }
        }
        return activities;
    } catch(err) {
        console.debug("err", err)
        return []
    }
}

export default getLast10TransactionsAndEvents;
