import React, { useCallback, useEffect, useState } from 'react';
import './Register.css'
import logofav from '../../Assets/images/iconlogo.webp'
import { Dropdown } from 'react-dropdown-now';
import useActiveWeb3React from '../../hooks/useWeb3';
import { useSelector } from 'react-redux';
import { useAccountInfo, useStake } from '../../state/account/hooks';
import { AGREEMENT, atxContract, BASE_URL, chainRPC, defaultNetwork, swapAddress, usdtContract, ValidatorAddress } from '../../config';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router';
import loaderGif from '../../Assets/images/loading.gif'
import Banner from '../../Components/Banner/Banner';
import { useContract, useStakeContract, useTokenContract } from '../../hooks/useContract';
import { ethers } from 'ethers';
import { Link } from 'react-router-dom';
import { registerToken } from '../../utils/setupNetwork'
import { useOcPrice } from '../../hooks/useOcPrice';
import ocicon from '../../Assets/images/iconlogo.webp'
import Web3 from 'web3';
import ValidatorABI from '../../config/abis/OxValidator.json'
import * as util from "ethereumjs-util";
import RegModal from '../../Components/RegModal/RegModal';
import FaqOuter from '../../Components/FaqOuter/FaqOuter';
import { toLocale } from '../../utils/pageHelpers';

const Loader = () => {
    return (
        <div className="loader"><img src={loaderGif} alt="loader GIF" /></div>
    )
}

const Register = (props) => {
    const [buttonPopup, setButtonPopup] = useState(false);
    const { chainId, account, library } = useActiveWeb3React()
    const { usdtBalance, isApproved, isRegistered } = useSelector((state) => state.account)
    const [upline, setUpline] = useState(1);
    const [eligible, setEligible] = useState(0);
    const [isInTrn, setTransaction] = useState(false);

    console.log(account, "account in register")

    const { approveUSDT, registerXgold } = useStake()
    const [wicon, setwIcon] = useState('info');
    const [nicon, setnIcon] = useState('info');
    const [ricon, setrIcon] = useState('info');
    const [bicon, setbIcon] = useState('info');
    const [sicon, setsIcon] = useState('info');

    const [buttonText, setbuttonText] = useState('Register Now');
    const location = useLocation();

    const [signData, setSignData] = useState({});
    const validatorInstance = useContract(ValidatorAddress, ValidatorABI)
    const planC = useStakeContract();


    const validateRegisterProcess = useCallback(async () => {

        if (!isApproved) {
            document.getElementById("nextButton").disabled = false;
            setbuttonText("Approve USDT");
        } else {
            if (!signData.v) {
                document.getElementById("nextButton").disabled = false;
                setbuttonText("Sign Agreement");
            } else {
                if (isRegistered) {
                    document.getElementById("nextButton").disabled = true;
                    toast.error("You are already registered please make login.");
                } else {
                    document.getElementById("nextButton").disabled = false;
                }
                setbuttonText("Register Now");
            }
        }

        const queryParams = new URLSearchParams(location.search);
        const referral = queryParams.get('referral');
        try {
    
            if (queryParams.get('referral')) {
                const qP = queryParams.get('referral')
                console.debug(qP, parseInt(qP))
                if ( parseInt(qP) && parseInt(qP) !== NaN ) {
                    setUpline(referral)
                }
            }
        } catch (error) {
            console.log(error)
            if (queryParams.get('referral')) {
                const qP = queryParams.get('referral')
                console.debug(qP, parseInt(qP))
                if ( parseInt(qP) && parseInt(qP) !== NaN ) {
                    setUpline(referral)
                }
            }
        }

        const walletConnectCheckDiv = document.getElementById('walletConnectCheck');
        const networkCheckDiv = document.getElementById('networkCheck');
        const registerCheckDiv = document.getElementById('registerCheck');
        const agreementCheckDiv = document.getElementById('agreementCheck');

        setEligible(0);
        console.log("validating register process", chainId, account)
        if (account) {
            walletConnectCheckDiv.classList.remove('error');
            walletConnectCheckDiv.classList.add('done');
            setwIcon('check_box');

            setEligible(eligible + 1);
        } else {
            walletConnectCheckDiv.classList.remove('done');
            walletConnectCheckDiv.classList.add('error');
            setwIcon('close');
        }

        if (chainId == defaultNetwork) {
            networkCheckDiv.classList.remove('error');
            networkCheckDiv.classList.add('done');
            setnIcon('check_box');
            setEligible(eligible + 1);
        } else {
            networkCheckDiv.classList.remove('done');
            networkCheckDiv.classList.add('error');
            setnIcon('close');
        }

        if (!isRegistered) {
            registerCheckDiv.classList.remove('error');
            registerCheckDiv.classList.add('done');
            setrIcon('check_box');
            setEligible(eligible + 1);
        } else {
            registerCheckDiv.classList.add('error');
            registerCheckDiv.classList.remove('done');
            setrIcon('close');
        }

        if (signData.v) {
            agreementCheckDiv.classList.remove('error');
            agreementCheckDiv.classList.add('done');
            setsIcon('check_box');
        } else {
            agreementCheckDiv.classList.remove('done');
            agreementCheckDiv.classList.add('error');
            setsIcon('close');
        }

    }, [location, setUpline, chainId, isApproved, usdtBalance, isRegistered, planC, account, signData])

    useEffect(() => {
        validateRegisterProcess();
    }, [account, usdtBalance, isApproved, isRegistered, isApproved, location, planC, signData, validateRegisterProcess])

    const navigate = useNavigate();

    useEffect(() => {
        console.log("checking balance", account)
        const timer = setInterval(() => {
            const balanceCheckDiv = document.getElementById('balanceCheck');
            if (parseFloat(usdtBalance) >= (10)) {
                balanceCheckDiv.classList.remove('error');
                balanceCheckDiv.classList.add('done');
                setbIcon('check_box');
                setEligible(eligible + 1);
            } else {
                balanceCheckDiv.classList.add('error');
                balanceCheckDiv.classList.remove('done');
                setbIcon('close');
            }
        }, 3500)
        return () => {
            console.log("clearing interval")
            clearInterval(timer)
        }
    }, [account, usdtBalance])

    const signMessage = useCallback(async () => {
        try {
            if (!account) {
                toast.error("Please connect your wallet first");
                return;
            }

            const signer = library.getSigner(account)
            const message = AGREEMENT

            const messageHash = ethers.utils.hashMessage(message);

            let signature = await signer.signMessage(message);
            let sig = ethers.utils.splitSignature(signature);
            // return;

            let r = sig.r;
            let s = sig.s;
            let v = sig.v;
            setSignData({ v, r, s, messageHash, signature });
        } catch (error) {
            console.error(error);
        }
    }, [account, library, validatorInstance])

    const validateUpline = useCallback((event) => {
        if (event.target.value) {
            setUpline(event.target.value)
        } else {
            setUpline('')
        }
    }, [setUpline])

    const approveBtn = useCallback(
        async () => {
            setTransaction(true)
            try {
                // console.log(account)
                await approveUSDT()
                // setTimeout( async ( ) => {
                await validateRegisterProcess();
                // }, 3000)
            } catch (err) {
                setTransaction(false)
            }
            setTransaction(false)
        },
        [approveUSDT, setTransaction, account],
    )

    const registerBtn = useCallback(
        async (upline) => {
            setTransaction(true)
            try {
                let resp = await registerXgold(upline, signData.v, signData.r, signData.s, signData.signature, signData.messageHash)
                if (resp) {
                    await validateRegisterProcess();
                    navigate("/")
                }
            } catch (err) {
                setTransaction(false)
            }
            setTransaction(false)
        },
        [registerXgold, setTransaction, signData],
    )

    const _doProcess = useCallback(async () => {
        if (!isApproved) {
            approveBtn();
        } else {
            if (!signData.v) {
                signMessage()
            } else {
                if (!upline || parseFloat(upline) == 0) {
                    toast.error("Invalid Upline ID")
                    return
                }
                registerBtn(upline);
            }
        }
    }, [upline, isApproved, account, approveBtn, registerBtn, signData, signMessage])

    const addBscTestnetToMetaMask = async () => {
        if (typeof window.ethereum === 'undefined') {
            toast.error('MetaMask is not installed. Please install MetaMask and try again.');
            return;
        }

        try {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    {
                        chainId: '0x89', // BSC Testnet chainId in hexadecimal
                        chainName: 'Polygon',
                        nativeCurrency: {
                            name: 'MATIC',
                            symbol: 'MATIC',
                            decimals: 18,
                        },
                        rpcUrls: [chainRPC],
                        blockExplorerUrls: ['https://polygonscan.com/'],
                    },
                ],
            });
        } catch (error) {
            console.error(error);
            toast.error('Error adding Polygon to MetaMask: ' + error.message);
        }
    }

    return (
        <>
            <Banner />
            <section className='registerMian'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-7 order-1 order-md-0'>
                            <div className='row'>
                                <div className='col-6 col-md-6'>
                                    <div className='inrBox' id='walletConnectCheck'>
                                        <i className="material-icons">{wicon}</i>
                                        <h3>Wallet Connected</h3>
                                    </div>
                                </div>
                                <div className='col-6 col-md-6'>
                                    <div className='inrBox' id='networkCheck'>
                                        <i className="material-icons">{nicon}</i>
                                        <h3>Network</h3>
                                    </div>
                                </div>
                                <div className='col-6 col-md-6'>
                                    <div className='inrBox' id='registerCheck'>
                                        <i className="material-icons">{ricon}</i>
                                        <h3>Registration</h3>
                                    </div>
                                </div>
                                <div className='col-6 col-md-6'>
                                    <div className='inrBox' id='balanceCheck'>
                                        <i className="material-icons">{bicon}</i>
                                        <h3>Balance ( &gt;= {toLocale(usdtBalance)} USDT )</h3>
                                    </div>
                                </div>
                                <div className='col-6 col-md-6'>
                                    <div className='inrBox' id='agreementCheck'>
                                        <i className="material-icons">{sicon}</i>
                                        <h3>Agreement Signed</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-5 order-0 order-md-1'>
                            <div className='titleBox'>
                                <h2 className='justify-content-center'>Registration in TecoMeta NFT</h2>
                            </div>
                            <div className='uplineBox'>
                                <label>Your upline</label>
                                <input type="text" value={upline} onChange={validateUpline} placeholder='Enter Your upline' />
                            </div>

                            {/* <div className='col-12 col-md-5 order-0 order-md-1 mb-5 mb-md-0'> */}
                            <div className='brn-grp text-center'>
                                {
                                    buttonText === "Sign Agreement" &&
                                    <button className='am_btn mb-3 ms-3' data-coreui-toggle="modal" href="#regModalToggle">Register</button>
                                }
                                {buttonText !== "Sign Agreement" &&
                                    <button disabled={isInTrn} className='am_btn' onClick={_doProcess} id='nextButton'> {isInTrn && <Loader />}
                                        {!isInTrn && buttonText}</button>
                                }
                                <p style={{ margin: "20px 0px 20px", fontSize: "20px" }}>You have already an account?</p>
                                <Link to={"/"}>
                                    <button className='am_btn mb-3'>Login</button>
                                </Link>
                            </div>
                            {/* </div> */}
                        </div>

                    </div>
                    <div className='row mt-5'>
                        <div className='col-12 col-md-7 order-1 order-md-0'>
                            <div className='titleBox'>
                                <h2 className='justify-content-center'>Mainnet & Token to Polygon</h2>
                            </div>
                            <div className='row tablemainnet'>
                                <div className='col-12 col-md-12'>
                                    <div className='mainnetInr' style={{ textAlign: 'center' }}>
                                        <h3>How to Add <span>Polygon</span> Mainnet</h3>
                                        <p>Add Polygon mainnet to metamask or other dapp browser to invest in TecoMeta NFT.</p>
                                        <div>
                                            <button onClick={addBscTestnetToMetaMask} className='am_btn  mx-3'>Add Mainnet</button >
                                            <button onClick={() => registerToken(usdtContract, "USDT", 18, "https://polygonscan.com/token/images/tether_32.png")} className='am_btn'>Add USDT</button >
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='col-12 col-md-6'>
                            <div className='tableInr'>
                                <h3>Token to BSC</h3>
                                <div className='table-responsive'>
                                    <table className="table table-striped-columns">
                                        <thead>
                                            <tr>
                                                <td><img src={logofav} alt="BSC" width="20" height="20" />BSC</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><img src={logofav} alt="ETH" width="20" height="20" />ETH</td>
                                                <td>Native</td>
                                                <td>439.57</td>
                                            </tr>
                                            <tr>
                                                <td><img src={logofav} alt="HBTC" width="20" height="20" />HBTC</td>
                                                <td>Native</td>
                                                <td>568.255</td>
                                            </tr>
                                            <tr>
                                                <td><img src={logofav} alt="MANA" width="20" height="20" />MANA</td>
                                                <td>Native</td>
                                                <td>982.34</td>
                                            </tr>
                                            <tr>
                                                <td><img src={logofav} alt="YFI" width="20" height="20" />YFI</td>
                                                <td>Native</td>
                                                <td>417.65</td>
                                            </tr>
                                            <tr>
                                                <td><img src={logofav} alt="HBTC" width="20" height="20" />HBTC</td>
                                                <td>Native</td>
                                                <td>541.25</td>
                                            </tr>
                                            <tr>
                                                <td><img src={logofav} alt="MANA" width="20" height="20" />MANA</td>
                                                <td>Native</td>
                                                <td>951.26</td>
                                            </tr>
                                            <tr>
                                                <td><img src={logofav} alt="YFI" width="20" height="20" />YFI</td>
                                                <td>Native</td>
                                                <td>852.15</td>
                                            </tr>
                                            <tr>
                                                <td><img src={logofav} alt="YFI" width="20" height="20" />YFI</td>
                                                <td>Native</td>
                                                <td>417.65</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FaqOuter />
            <RegModal props={props} trigger={buttonPopup} isInTrn={isInTrn} buttonText={buttonText} onSign={_doProcess} setTrigger={setButtonPopup} />
        </>
    )
}

export default Register