import React, { useCallback, useEffect, useState } from 'react'
import './LevelsDetails.css'
import CIcon from '@coreui/icons-react'
import { cilContact } from '@coreui/icons'
import { cilMeh } from '@coreui/icons'
import userIcon from '../../Assets/images/icons/user-icon.webp'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import incomeIcon from '../../Assets/images/icons/income-icon.webp'
import useActiveWeb3React from '../../hooks/useWeb3'
import { useStakeContract, useUniLevelContract } from '../../hooks/useContract'
import { toast } from 'react-toastify'
import { ethers } from 'ethers'
import { getUserLevelTreeCountUni } from './fetchUserCount'
import { BASE_BSC_SCAN_URLS } from '../../config'
function LevelsDetails() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const { library, chainId, account } = useActiveWeb3React();
    const [userId, setUserId] = useState(0);
    const [slot, setSlot] = useState(0);
    const [level, setLevel] = useState(0);
    const [totalUser, setTotalUser] = useState(0);
    const [totalEarning, setTotalEarning] = useState(0);
    const [userAddress, setUserAddress] = useState('');
    const [levelUsers, setLevelUsers] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();
    const planC = useStakeContract();
    const planUni = useUniLevelContract();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const user_id = queryParams.get('user_id');
        const slot = queryParams.get('slot');
        const level = queryParams.get('level');
        if (queryParams.get('user_id')) {
            setUserId(user_id)
        }
        if (queryParams.get('slot')) {
            setSlot(slot)
        }
        if (queryParams.get('level')) {
            setLevel(level)
        }
    }, [setUserId, setSlot, setLevel, location])

    useEffect(() => {
        if (planC && userId !== 0) {
            fetSLOTLevels();
        }
    }, [userId, planC])

    const fetSLOTLevels = useCallback(async () => {
        let actDiv = document.getElementById('transactionUserLevels');
        actDiv.classList.add('loaderData');
        let userAddress;
        userAddress = await planC.getAddressById(userId);

        const userInfoResponse = await planC.userInfos(userAddress);

        if (userInfoResponse.joined == false) {
            toast.error("This user id does not exist");
            navigate("/");
        }

        let slotUsers = await planUni.userSlotCount(userAddress, slot);
        let slotEarning = await planUni.userSlotLevelEarnings(userAddress, slot, level-1);

        setTotalEarning(ethers.utils.formatEther(slotEarning))
        setTotalUser(ethers.BigNumber.from(slotUsers).toNumber())

        let usersLevelTree = await getUserLevelTreeCountUni(userAddress, slot, level);
        const listItems = [];
        for (let i = 0; i < usersLevelTree.length; i++) {
            let dta = usersLevelTree[i]
            let data = {};
            
            let getUser = await planC.userInfos(dta.downUser);
            const getReferrer = await planC.userInfos(dta.referrer);

            data.amount = ethers.utils.formatEther(dta.amount);
            data.user = parseInt(getUser.id);
            data.referrer = parseInt(getReferrer.id);
            data.txn = dta.transactionHash;

            listItems.push(data)
        }
        setLevelUsers(listItems)
        actDiv.classList.remove('loaderData');
        // data = userLevelTree(userAddress,slot,level,i)
        // node user address - data.user
        // node user referrer - data.referrer
        // node user amount - data.amount

        // console.log("asd", usersLevelTree);
    });
    return (
        <>
            <section className="treeLevels">
                <div className='titleBox'>

                    <h2>
                        <NavLink className={"backIcon"} to={"/levels?user_id=" + userId} onClick={scrollToTop}>
                            <span>&#60;</span> back
                        </NavLink>
                    </h2>
                </div>
                <div className='levelDetailsTable'>
                    <div className='row levelDetailsBox '>
                        <div className='col-12 col-sm-3 col-lg-3 mb-4'>
                            <div className='inrBox'>
                                <p>Slot</p>
                                <h3>{slot}</h3>
                                <img src={incomeIcon} alt="Icom icon" width="70" height="70" />
                            </div>
                        </div>
                        <div className='col-12 col-sm-3 col-lg-3 mb-4'>
                            <div className='inrBox'>
                                <p>Level</p>
                                <h3>{level}</h3>
                                <img src={userIcon} alt="user icon" width="70" height="70" />
                            </div>
                        </div>
                        <div className='col-12 col-sm-3 col-lg-3 mb-4'>
                            <div className='inrBox'>
                                <p>Total Income</p>
                                <h3>{parseFloat(totalEarning ?? "0").toFixed(2)} USDT</h3>
                                <img src={incomeIcon} alt="Icom icon" width="70" height="70" />
                            </div>
                        </div>
                        <div className='col-12 col-sm-3 col-lg-3 mb-4'>
                            <div className='inrBox'>
                                <p>Total Users</p>
                                <h3>{totalUser}</h3>
                                <img src={userIcon} alt="user icon" width="70" height="70" />
                            </div>
                        </div>
                    </div>
                    <div class="titleBox mt-4"><h2 className='justify-content-center'>Transaction History</h2></div>
                    <div className='tableInr' id='transactionUserLevels'>
                        <div className='table-responsive'>
                            <table className="table table-striped-columns">
                                <thead>
                                    <tr className='headSec'>
                                        <th>User ID</th>
                                        <th>Sponsor</th>
                                        <th>Earning</th>
                                        <th>Tx Hash</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {levelUsers.map((a, index) => {
                                        return <tr key={index}>
                                            <td><a href="#!" className='idbox'>ID {a.user}</a></td>
                                            <td>{a.referrer}</td>
                                            <td>{parseFloat(a.amount).toFixed(2)}</td>
                                            <td className='linkshr text-end'>
                                                <span>View Txn</span>
                                                <a href={`${BASE_BSC_SCAN_URLS}/tx/${a.txn}`} target="blank">
                                                    <svg className="w-6 h-6" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.232 7.232A2.5 2.5 0 0 1 6 6.5h5a.5.5 0 0 1 0 1H6A1.5 1.5 0 0 0 4.5 9v9A1.5 1.5 0 0 0 6 19.5h9a1.5 1.5 0 0 0 1.5-1.5v-5a.5.5 0 0 1 1 0v5a2.5 2.5 0 0 1-2.5 2.5H6A2.5 2.5 0 0 1 3.5 18V9a2.5 2.5 0 0 1 .732-1.768Z"></path><path fillRule="evenodd" clipRule="evenodd" d="M20.354 3.646a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.708-.708l10-10a.5.5 0 0 1 .708 0Z"></path><path fillRule="evenodd" clipRule="evenodd" d="M14.5 4a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V4.5H15a.5.5 0 0 1-.5-.5Z"></path></svg>
                                                </a>
                                            </td>
                                        </tr>
                                    })}

                                    {levelUsers.length == 0 ? <tr><td colSpan={4}>No Record Found</td></tr> : ''}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LevelsDetails