import React, { useCallback, useEffect, useState } from 'react';
import './Partners.css'
import copyIcon from '../../Assets/images/copy.png'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';
import Footer from '../../Components/Footer/Footer';
import fetchDirectPaidEvents from '../../services/partners';

import useActiveWeb3React from '../../hooks/useWeb3';
import { useLocation } from 'react-router';
import { ethers } from 'ethers';

const copySuccessfully = () => toast.success('Copy Successfully!');
const Partners = () => {
    const { library, chainId, account } = useActiveWeb3React()
    const [userId, setUserId] = useState(0);
    const [userPartner, setUserPartner] = useState([]);
    const location = useLocation();
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const user_id = queryParams.get('user_id');
        if (queryParams.get('user_id')) {
            setUserId(user_id)
        }

    }, [location])

    useEffect(() => {
        fetchPartnerDetails();
    }, [userId])

    const fetchPartnerDetails = useCallback(async () => {
        console.log(userId)
        if ( userId === 0 ) return
        let actDiv = document.getElementById('partnersView');
        actDiv.classList.add('loaderData');
        let partners = await fetchDirectPaidEvents(userId);

        setUserPartner(partners)
        actDiv.classList.remove('loaderData');
    }, [userId])

    function getDate(x) {
        const myDate = new Date(x * 1000);
        return myDate;
    }

    return (
        <>
            <section className='partnersPreviewMain'>
                <div className='titleBox'>
                    <h2>Partners</h2>
                </div>
                <div className='row tablemainnet'>
                    <div className='tableInr' id='partnersView'>
                        <div className='table-responsive'>
                            <table className="table table-striped-columns">
                                <thead>
                                    <tr>
                                        <th width="">Date</th>
                                        <th width="">Tx Hash</th>
                                        <th width="">ID</th>
                                        <th width="">Referral Earning</th>
                                        <th width="">Level</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userPartner.map((a, index) => {
                                        return (<tr key={index}>
                                            <td>{getDate(a.blockTimestamp).toDateString()}</td>
                                            <CopyToClipboard text={a.transactionHash}>
                                                <td onClick={() => { copySuccessfully(); }}>{a.transactionHash.substr(0, 4)} ... {a.transactionHash.substr(-4)} <img src={copyIcon} className="copyIocn" alt="copy icon" /></td>
                                            </CopyToClipboard>

                                            <td><a href={`/dashboard?user_id=${a.from}`}>ID {a.from}</a></td>
                                            <td>{ethers.utils.formatUnits(a.amount, 18)} USDT</td>
                                            <td>{a.level}</td>
                                        </tr>)
                                    })}

                                    {userPartner.length == 0 ? <tr><td colSpan={5}>No Record Found</td></tr> : ''}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Partners