import React, { useCallback, useEffect, useState } from 'react'
import './Stats.css'
import logofav from '../../Assets/images/iconlogo.webp'
import copyIcon from '../../Assets/images/copy.png'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';
import Footer from '../../Components/Footer/Footer';
import useActiveWeb3React from '../../hooks/useWeb3';
import { useLocation } from 'react-router';
import fetchDirectStatsEvents from '../../services/stats';
import { ethers } from 'ethers';
const copySuccessfully = () => toast.success('Copy Successfully!');
const Stats = () => {
    const { library, chainId, account } = useActiveWeb3React()
    const [userId, setUserId] = useState(0);
    const [userStats, setUserStats] = useState([]);
    const location = useLocation();
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const user_id = queryParams.get('user_id');
        if (queryParams.get('user_id')) {
            setUserId(user_id)
        }

    }, [account, location])

    useEffect(() => {
        fetchPartnerDetails();
    }, [userId, location])

    const fetchPartnerDetails = useCallback(async () => {
        if ( userId === 0 ) return
        let actDiv = document.getElementById('statsView');
        actDiv.classList.add('loaderData');
        console.log(userId)
        let stats = await fetchDirectStatsEvents(userId);

        console.log("FSTATS", stats);
        setUserStats(stats)
        actDiv.classList.remove('loaderData');
    }, [userId])

    function getDate(x) {
        const myDate = new Date(x * 1000);
        return myDate;
    }
    return (
        <>
            <section className='statsPreviewMain'>
                <div className='titleBox'>
                    <h2>Stats</h2>
                </div>
                <div className='row tablemainnet'>
                    <div className='tableInr' id='statsView'>
                        <div className='table-responsive'>
                            <table className="table table-striped-columns">
                                <thead>
                                    <tr>
                                        <th width="">Date</th>
                                        <th width="">Level</th>
                                        <th width="">ID</th>
                                        <th width="">Hash</th>
                                        <th width="">Earnings</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userStats.map((a, index) => {
                                        return (<tr key={index}>
                                            <td>{getDate(a.blockTimestamp).toDateString()}</td>
                                            <td>{a.level}</td>

                                            <td><a href={`/dashboard?user_id=${a.from}`}>ID {a.from}</a></td>
                                            <CopyToClipboard text={a.transactionHash}>
                                                <td onClick={() => { copySuccessfully(); }}>{a.transactionHash.substr(0, 4)} ... {a.transactionHash.substr(-4)} <img src={copyIcon} className="copyIocn" alt="copy icon" /></td>
                                            </CopyToClipboard>
                                            <td>{ethers.utils.formatUnits(a.amount, 18)} USDT</td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Stats