import { graphURL } from '../config';

import axios from 'axios';

async function fetchDirectStatsEvents(userId) {
    const options = {
        method: 'POST',
        url: graphURL,
        headers: {
            'content-type': 'application/json'
        },
        data: {
            query: `{
              treePayoutEvents( orderBy: blockTimestamp
                orderDirection: desc
                where:{ to: "${userId}" } ) {
                id
                from
                amount
                matrix
                level
                transactionHash
                blockTimestamp
              }
            }`
        }
    };

    const response = await axios.request(options);
    const res = response.data; // Response received from the API
    
    return res.data.treePayoutEvents;  
}

export default fetchDirectStatsEvents;