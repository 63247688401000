import React, { useCallback } from 'react'
import './Banner.css'
import slider1 from '../../Assets/images/banner-slider/slider1.jpg'
import slider2 from '../../Assets/images/banner-slider/slider2.jpg'
import Carousel from 'nuka-carousel';
import { useNavigate } from 'react-router';
import useActiveWeb3React from '../../hooks/useWeb3';
import toast, { Toaster } from 'react-hot-toast';
import { useStakeContract } from '../../hooks/useContract';

const Banner = () => {

    const navigate = useNavigate();
    const { library, chainId, account } = useActiveWeb3React()
    const planC = useStakeContract();

    const makeAutoLogin = useCallback(async () => {
        if (!account) {
            toast.error("Connect wallet!")
            return
        }
        try {
            const userInfo = await planC.userInfos(account)
            // console.log(userInfo)
            if (userInfo.joined) {
                navigate("/dashboard?user_id=" + parseInt(userInfo.id))
            } else {
                navigate("/register")
            }
        } catch (Err) {
            console.log(Err)
        }
    }, [account, planC])
    
    return (
        <>
            <section className="sf_banner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6 text-start order-1 order-lg-0">
                            <div className='contentinfolt'>
                                <h1>Discover the future of digital ownership</h1>
                                <p>Experience a paradigm shift in digital ownership on our NFT platform.</p>
                                <button onClick={makeAutoLogin} className='btnSfx'>Auto-Login »</button>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 text-end  order-0 order-lg-1">
                            <div className='sliderbanner'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="143" height="143" fill="none" id="star"><path fill="#3B404E" d="M142.5 72C103.6 72 72 103.6 72 142.5c0 .3-.2.5-.5.5s-.5-.2-.5-.5C71 103.6 39.4 72 .5 72c-.3 0-.5-.2-.5-.5s.2-.5.5-.5C39.4 71 71 39.4 71 .5c0-.3.2-.5.5-.5s.5.2.5.5C72 39.4 103.6 71 142.5 71c.3 0 .5.2.5.5s-.2.5-.5.5zm-39-52.5c10.5 0 19-8.5 19-19 0-.3.2-.5.5-.5s.5.2.5.5c0 10.5 8.5 19 19 19 .3 0 .5.2.5.5s-.2.5-.5.5c-10.5 0-19 8.5-19 19 0 .3-.2.5-.5.5s-.5-.2-.5-.5c0-10.5-8.5-19-19-19-.3 0-.5-.2-.5-.5s.2-.5.5-.5zm-70 104c-7.2 0-13 5.8-13 13 0 .3-.2.5-.5.5s-.5-.2-.5-.5c0-7.2-5.8-13-13-13-.3 0-.5-.2-.5-.5s.2-.5.5-.5c7.2 0 13-5.8 13-13 0-.3.2-.5.5-.5s.5.2.5.5c0 7.2 5.8 13 13 13 .3 0 .5.2.5.5s-.2.5-.5.5z" opacity="1"></path></svg>
                                <Carousel wrapAround={true} slidesToShow={1} autoplay={true}>
                                    <img src={slider1} alt="slider1" width="1200" height="500" />
                                    <img src={slider2} alt="slider2" width="1200" height="500" />
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Banner