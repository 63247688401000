import { formatEther } from "ethers/lib/utils";
import { levelPrices } from "../config";

function shortAddress(address) {
    return `${address.substr(0, 4)}...${address.substr(-4)}`;
}

function formatNumber(val, decimals=4) {
    return parseFloat(parseFloat(formatEther(val)).toFixed(decimals)).toLocaleString()
}

function calculateTotalInvested(toLevel) {
    let _total = 0
    levelPrices.forEach((ele, idx) => idx < toLevel && (_total += ele) )
    return _total
}

export {
    shortAddress,
    formatNumber,
    calculateTotalInvested
}