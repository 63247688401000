import useActiveWeb3React from "./useWeb3";
import { useMemo } from "react";
import { getContract, getSigner } from "../utils/getContract";
import stakeABI from '../config/abis/OxNFTAbi.json';
import updateABI from '../config/abis/UpdatePool.json';
import oxNFTABI from '../config/abis/OxNFT.json';
import tokenABI from '../config/abis/tokenABI.json';
import OxUniLevelABI from '../config/abis/OxUniLevelABI.json';
import { OxNFTContract, OxUniLevel, UpdateAllPool } from "../config";
import PoolABI from "../abi/smmartfoxPool.json";

export const useContract = (address, abi) => {
    const { library, account } = useActiveWeb3React()

    return useMemo(() => getContract(address, abi, account ? library.getSigner() : getSigner()), [address, abi, library, account]);
}

export function useStakeContract() {
    return useContract(OxNFTContract, stakeABI)
}

export function useUpdateAllPoolContract() {
    return useContract(UpdateAllPool, updateABI)
}

export function useTokenContract(tokenAddress) {
    return useContract(tokenAddress, tokenABI)
}

export function useUniLevelContract() {
    return useContract(OxUniLevel, OxUniLevelABI)
}

export const useNFTInstance = (address) => {
    return useContract(address, oxNFTABI)
}

export function useRoyaltyPoolContract(royaltyPool) {
    return useContract(royaltyPool, PoolABI)
}