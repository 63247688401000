import React from 'react';
import { CRow, CCol, } from '@coreui/react'
import { Home, Info, EvStationSharp, PoolTwoTone, People, QueryStatsIcon } from '@material-ui/icons';
import { FaTelegramPlane, FaRegEnvelope, FaTwitter, FaMediumM, FaInstagram, FaRedditAlien, FaFacebookF, FaYoutube, FaLinkedinIn, FaDiscord } from "react-icons/fa";
import './Footer.css'
const Footer = () => {

    return (
        <footer>
            <div className='container'>
                <CRow className='align-items-center'>
                    <CCol sm={12} md={4}><p>© 2023 All Rights Reserved</p></CCol>
                    <CCol sm={12} md={8} className="text-right">
                        <div className="socialIcons">
                            <ul>

                                <li>
                                    <a href="https://www.facebook.com/profile.php?id=61554685972032" rel="noreferrer" title="Facebook" target="_blank">
                                        <FaFacebookF />
                                    </a>
                                </li>
                                <li>
                                    <a href=" https://www.instagram.com/tecometa_official/" rel="noreferrer" title="Instagram" target="_blank">
                                        <FaInstagram />
                                    </a>
                                </li>
                                <li>
                                    <a href="  https://t.me/tecometaofficial" rel="noreferrer" title="Telegram" target="_blank">
                                        <FaTelegramPlane />
                                    </a>
                                </li>
                                <li>
                                    <a href="  https://discord.com/channels/1116716896985813012/1116716954615545937" rel="noreferrer" title="Telegram" target="_blank">
                                        <FaDiscord />
                                    </a>
                                </li>

                                <li>
                                    <a href="https://twitter.com/TecoMetaOffici" rel="noopener noreferrer" title="Twitter" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">

                                            <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                                        </svg>
                                    </a>
                                </li>

                                <li>
                                    <a href="https://medium.com/@tecometa.com" rel="noreferrer" title="Medium" target="_blank">
                                        <FaMediumM />
                                    </a>
                                </li>

                                <li>
                                    <a href=" https://www.reddit.com/user/TecoMeta_Official" rel="noreferrer" title="Reddit" target="_blank">
                                        <FaRedditAlien />
                                    </a>
                                </li>

                                <li>
                                    <a href=" https://youtube.com/@TecoMeta" rel="noreferrer" title="Youtube" target="_blank">
                                        <FaYoutube />
                                    </a>
                                </li>

                                {/* <li>
                                    <a href="#!" rel="noreferrer" title="Gmail" target="_blank">
                                        <FaRegEnvelope />
                                    </a>
                                </li> */}
                                <li>
                                    <a href="https://www.linkedin.com/company/tecometa-official/" rel="noreferrer" title="Gmail" target="_blank">
                                        <FaLinkedinIn />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </CCol>
                </CRow>
            </div>
        </footer>
    )
}

export default Footer