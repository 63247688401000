import React, { useCallback, useEffect, useState } from 'react'
import './poolDetails.css'
import logofav from '../../Assets/images/iconlogo.webp';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';
import useActiveWeb3React from '../../hooks/useWeb3';
import { useRoyaltyPoolContract, useStakeContract } from '../../hooks/useContract';
import { ethers } from 'ethers';
import { useLocation, useNavigate } from 'react-router';
import { Pool1, Pool3, names } from '../../config';
import { Pool2 } from '../../config';
import { shortAddress } from '../../utils';
import ClaimABI from '../../abi/smmartfoxClaim.json'
import { getSigner } from '../../utils/getContract';
import { showFulfill } from '../../utils/pageHelpers';
import { formatUnits } from 'ethers/lib/utils';



const copySuccessfully = () => toast.success('Copied Successfully!');

function PoolDetails() {
    const { library, chainId, account } = useActiveWeb3React()

    const [isInTrn, setTransaction] = useState(false);
    const [PoolAddress, setPoolAddress] = useState("");
    const [dis, setDis] = useState(false);
    const [levelBought, setLevelBought] = useState(0);

    const [userId, setUserId] = useState(0);
    const [round, setRound] = useState(0);
    const [userAddress, setUserAddress] = useState("");
    const [poolDetailsState, setPoolDetailsState] = useState([]);
    const [activePool, setActivePool] = useState({});

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const lock = useRoyaltyPoolContract(Pool1)


    useEffect(() => {
        const user_id = queryParams.get('user_id');
        const round = queryParams.get('round');
        if (queryParams.get('user_id')) {
            setUserId(user_id)
        }
        if (queryParams.get('round')) {
            setRound(round)
        }
        fetchPanelDetails();
        if (account) {
            setUserAddress(account)
        }

    }, [account, location])

    const planC = useStakeContract();

    const fetchPanelDetails = useCallback(async () => {
        let userAddress;
        userAddress = account;

        try {

            const userInfoResponse = await planC.userInfos(userAddress);
            const levelBought = ethers.BigNumber.from(userInfoResponse["levelBought"]).toNumber();
            if (levelBought > 0) {
                if (levelBought > 15) {
                    setLevelBought(15)
                } else {
                    setLevelBought(levelBought)
                }
            }

        } catch (err) {
            console.debug(err)
        }

        try {
            const poolitemC = [];
            // let pools = await lock.getPoolLength()
            // pools = parseInt(pools);

            // for (let i = 0; i < pools; i++) {
            let data = {};
            // let round = await lock.rounds(i)

            // let roundDetails = await lock.poolDetails(round)

            let roundInstance = new ethers.Contract(round, ClaimABI, account ? library.getSigner() : getSigner())

            let totalCollected = await roundInstance.totalCollected()
            let perShare = await roundInstance.getLivePerShareValue()
            let totalShare = await roundInstance.totalShares()
            let endTime = await roundInstance.end()
            let startTime = await roundInstance.start()
            if (userAddress) {
                let userShare = await roundInstance.user(userAddress);
                data.userShare = parseInt(userShare.shares);
                data.claimed = parseInt(userShare.returned);
            }
            // let updateShare = await roundInstance.updateMyShare()
            // let claim = await roundInstance.claim()
            perShare = ethers.utils.formatEther(perShare)
            data.end = new Date(parseInt(endTime) * 1000);
            data.start = new Date(parseInt(startTime) * 1000);
            data.perShare = parseFloat(perShare);
            data.totalShare = parseFloat(totalShare);
            // data.isActive = roundDetails.isActive;

            data.roundAddress = round;
            data.totalCollected = ethers.utils.formatUnits(totalCollected, 18);
            poolitemC.push(data);

            console.log(data)

            // if (roundDetails.isActive) {
            setActivePool(data)
            // }
            // }
            // setPoolDetailsState(poolitemC);
        } catch (err) {
            console.log(err)
        }

    }, [account, lock, round, library])
    const navigate = useNavigate();

    async function updateShare() {
        let roundInstance = new ethers.Contract(round, ClaimABI, account ? library.getSigner() : getSigner())
        let txn = await roundInstance.updateMyShare()
        showFulfill(`Transaction was submitted with txn hash: ${shortAddress(txn.hash)}`)
        await txn.wait()
        showFulfill(`Share Updated Sucessfully`, 'Transaction Confirmed')
    }

    useEffect(() => {
        const inter = setInterval(() => {
            fetchPanelDetails();
        }, 12000)

        return () => {
            clearInterval(inter)
        }
    }, [fetchPanelDetails])

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const user_id = queryParams.get('user_id');
        const Pool = queryParams.get('pool');
        if (Pool == 1) {
            setPoolAddress(Pool1)
        }
        else if (Pool == 2) {

            setPoolAddress(Pool2)
        }
        else if (Pool == 3) {
            setDis(true)
            setPoolAddress(Pool3)
        }

        else {
            navigate(`/dashboard?user_id=${user_id}`)
        }
        if (queryParams.get('user_id')) {
            setUserId(user_id)
        }
    }, [setUserId, location])

    return (
        <>
            <section className='dashboardMian dashboardPreviewMain poolmain'>

                <div className='row tablemainnet mx-auto'>
                    <div className='col-12 col-md-8 order-1 order-md-0'>
                        <div className='row dash-box'>
                            <div className='col-12 col-sm-6 mb-4 mt-4'>
                                <div className='inrBox'>
                                    <p>Total Claimed</p>
                                    <h3>{activePool.claimed ?? 0}</h3>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 mb-4 mt-0 mt-sm-4'>
                                <div className='inrBox'>
                                    <p>Total Shares</p>
                                    <h3>{activePool.totalShare ?? 0}</h3>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 mb-4'>
                                <div className='inrBox d-flex align-items-center justify-content-between flex-wrap'>
                                    <div>

                                        <p>Your Shares</p>
                                        <h3>{parseFloat(activePool.userShare ?? "0") * parseFloat(activePool.perShare ?? "0")}</h3>
                                    </div>
                                    <button className='am_btn'
                                        disabled={userId == 30 ? true : false}
                                        onClick={() => { updateShare(); }} style={{ fontSize: '12px', padding: '12px 18px' }}>Update My Share</button>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 mb-4'>
                                <div className='inrBox'>
                                    <p>Share Value</p>
                                    <h3>{Number((parseFloat(activePool.perShare ?? "0")).toFixed(4))}</h3>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 mb-4'>
                                <div className='inrBox'>
                                    <p>Pool USDT</p>
                                    <h3>{activePool.totalCollected ?? '0'}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-4 order-0 order-md-0 progileDashBx d-flex justify-content-end p-0 ps-md-2'>

                        <div class="card justify-content-start">
                            <div class="infos mt-2">
                                <div class="image"> <img className="prfllgo" src={logofav} style={{ borderRadius: "5px" }} alt="ox profile" /></div>
                                <div class="info">
                                    <div>
                                        <p class="name">
                                            ID # {userId}
                                        </p>
                                        <p class="function">
                                            {/* {data['name'] && <>{data['name']}</>} */}
                                            <br />
                                            Rank: {names[levelBought - 1]}
                                        </p>

                                    </div>

                                </div>
                            </div>
                            <CopyToClipboard text={userAddress} className="request">
                                <p>{userAddress.substr(0, 4)}...{userAddress.substr(-4)}
                                    <svg onClick={() => { copySuccessfully(); }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon" role="img"><polygon fill="var(--ci-primary-color, currentColor)" points="408 432 376 432 376 464 112 464 112 136 144 136 144 104 80 104 80 496 408 496 408 432" class="ci-primary"></polygon><path fill="var(--ci-primary-color, currentColor)" d="M176,16V400H496V153.373L358.627,16ZM464,368H208V48H312V200H464Zm0-200H344V48h1.372L464,166.627Z" class="ci-primary"></path></svg>
                                </p>
                            </CopyToClipboard>
                            {/* <div className="stats">
                                <p className="flex flex-col">

                                    <span className="state-value">
                                        <a href={`https://t.me/oxcashchat`} target="_blank" title='Telegram'>
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"></path></svg>
                                        </a>
                                    </span>
                                </p>
                                <p className="flex">

                                    <span className="state-value">
                                        <a href={`https://t.me/Oxchange_Finance`} target="_blank" title='Telegram'>
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"></path></svg>
                                        </a>
                                    </span>
                                </p>

                            </div> */}
                        </div>


                    </div>

                </div>
            </section>

        </>
    )
}

export default PoolDetails