import { useCallback, useEffect, useMemo, useState } from "react"
import useActiveWeb3React from "./useWeb3"
import { useContract } from "./useContract"
import { AGREEMENT, ValidatorAddress } from "../config"
import ValidatorABI from '../config/abis/OxValidator.json'
import { showPromise } from "../utils/pageHelpers"
import { ethers } from "ethers"

export const useIsSigned = ({userAddress}) => {
    const { account } = useActiveWeb3React()
    const [isSigned, setIsSigned] = useState(true)
    
    const validatorInstance = useContract(ValidatorAddress, ValidatorABI)
    useEffect(() => {
        const timer = setInterval(async () => {
            if ( !account ) return
            if ( !userAddress ) return
                console.debug(userAddress, account)
                if ( account.toLowerCase() !== userAddress.toLowerCase() ) {
                    setIsSigned(true)
                    return
                }
                const instance = await validatorInstance.userMessages(account)
                if ( parseInt(instance.timestamp) === 0 ) {
                    setIsSigned(false)
                }
        }, 12000)

        return () => clearInterval(timer)
    }, [account, userAddress, validatorInstance, setIsSigned])

    useEffect(() => {
        (
            async() => {
                if ( !account ) return
                if ( !userAddress ) return
                if ( account.toLowerCase() !== userAddress.toLowerCase() ) {
                    setIsSigned(true)
                    return
                }
                const instance = await validatorInstance.userMessages(account)
                if ( parseInt(instance.timestamp) === 0 ) {
                    setIsSigned(false)
                }
            }
        )()
    },[account, userAddress, validatorInstance, setIsSigned])

    return useMemo(() => {
        return {
            isSigned
        }
    }, [isSigned])
}

export const useSignMessage = () => {
    const { account, library } = useActiveWeb3React()
    const validatorInstance = useContract(ValidatorAddress, ValidatorABI)

    const signMessage = useCallback(async () => {
        const signer = library.getSigner(account)
        const message = AGREEMENT

        const messageHash = ethers.utils.hashMessage(message);

        let signature = await signer.signMessage(message);
        let sig = ethers.utils.splitSignature(signature);

        let r = sig.r;
        let s = sig.s;
        let v = sig.v;

        let txn = await validatorInstance.signNotSignedAddresses(
            messageHash,
            v,
            r,
            s,
            signature
        )

        await showPromise(txn.wait(), "Signing message", "Message signed successfully", "Message signing failed")
    }, [validatorInstance, library, account])

    return {
        signMessage
    }
}