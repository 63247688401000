import React from 'react'
const About = () => {
    return (
        <>
            <section className='homePageMain'>
                <div className='titleBox'>
                    <h2>About Us</h2>
                </div>
            </section>
        </>
    )
}

export default About