import BigNumber from 'bignumber.js'
import {createSlice} from "@reduxjs/toolkit";
import { fetchAccountInfo } from '../../state/account';

const initialState = {
    usdtBalance: 0,
    isApproved: false,
    isRegistered : false,
}

const accountSlice = createSlice({
    name: "account",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchAccountInfo.fulfilled, (state, action) => {
            const data = action.payload;
            state.usdtBalance = data.usdtBalance
            state.isApproved = data.isApproved
            state.isRegistered = data.isRegistered
        })
    }
})


export default accountSlice.reducer