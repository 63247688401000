export const nftAddress = {
    1: "0x6767aD2B27F261bDe88Df4B9AeB4faC05A6e1C57",
    2: "0xCB76e655CC32c05eEA68108C0c4Ca5fAfa81CE0f",
    3: "0x1cEdd31b49Ab0E587d7f6292996ea7F6092DA45E",
    4: "0x963E7754c444F21188388ac2c52FCE54a0b4c1d0",
    5: "0x61630960df141DCDF76BD66A2Fff509A020b3fCa",
    6: "0xF97d6921C7892Ad4fa883a6A2cef8c18a9ae95cD",
    7: "0xE273D3d82F46b71E910080C63Efa8789cfA18744",
    8: "0xa3C4a43C4F7f762B92D7C91973171907Bc539Db6",
    9: "0x96B029Cb806A1185B15e1Bf0D09Debaca724E3ba",
    10: "0x9B81df80cB1317a1853aa2B9B55e1323484E61b0",
    11: "0x26Bc0f55607568Eb563d03D430475D36639C2587",
    12: "0xAd59D9F67Ad9fbe9bA75908973C0B777ed286fbE",
    13: "0x5EdBe30b1fCde7233eb943Dce3862423fc6aDb95",
    14: "0x38CCFe5Bfa346a6f1C90ef0E35B79978677A75D1",
    15: "0xB49239D5dCD1d694819e9d7938cEF7fF433abE49"
}