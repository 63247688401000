import sample from 'lodash/sample'
import { chainRPC } from '../config'
// Array of available nodes to connect to
export const nodes = [chainRPC]

const getNodeUrl = () => {
    // Use custom node if available (both for development and production)
    // However on the testnet it wouldn't work, so if on testnet - comment out the VITE_APP_NODE_PRODUCTION from env file
    // if (import.meta.env.VITE_APP_NODE_PRODUCTION) {
    //     return import.meta.env.VITE_APP_NODE_PRODUCTION
    // }
    return sample(nodes)
}

export default getNodeUrl