import { ethers } from 'ethers';
import { graphURL } from '../config';
import axios from 'axios';

async function fetchIncomeLast24h() {
    const nowTimestamp = Math.floor(Date.now() / 1000);

    // Calculate the timestamp for 24 hours ago
    const oneDayInSeconds = 24 * 60 * 60;
    const timestamp24HoursAgo = nowTimestamp - oneDayInSeconds;

    const options = {
        method: 'POST',
        url: graphURL,
        headers: {
            'content-type': 'application/json'
        },
        data: {
            query: `{
                treePayoutEvents(where: {blockTimestamp_gte: ${timestamp24HoursAgo}, blockTimestamp_lte: ${nowTimestamp}}) {
                  id
                  from
                  amount
                  transactionHash
                }
                
                directPaidEvents(where: {blockTimestamp_gte: ${timestamp24HoursAgo}, blockTimestamp_lte: ${nowTimestamp}}) {
                  id
                  from
                  amount
                  transactionHash
                }
              }`
        }
    };

    const response = await axios.request(options);
    const res = response.data;

    const treePayoutEvents = res.data.treePayoutEvents || [];
    const directPaidEvents = res.data.directPaidEvents || [];

    const sumTreePayoutEvents = treePayoutEvents.reduce((sum, event) => sum + parseFloat(ethers.utils.formatEther(event.amount)), 0);
    const sumDirectPaidEvents = directPaidEvents.reduce((sum, event) => sum + parseFloat(ethers.utils.formatEther(event.amount)), 0);

    return sumDirectPaidEvents + sumTreePayoutEvents;
}

export default fetchIncomeLast24h;