import {createAsyncThunk} from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { OxNFTContract } from "../../config";
import AeromatiXAbi from "../../config/abis/AeromatiX.json"
import { formatNumber } from "../../utils";
import { getContract } from "../../utils/getContract";

export const fetchStakeInfo = createAsyncThunk('stake/fetchStakeInfo', async (address) => {
    try {
        const stakeInstance = getContract(OxNFTContract, AeromatiXAbi)
        const userData = await stakeInstance.getIdByAddress(address)
        console.log(userData);
        return {
            returned: userData.toJSON()
        }
    } catch(err) {
        console.log(err)
        return {
            returned: 0
        }
    }
})
