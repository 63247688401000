import { graphURL } from '../config';

import axios from 'axios';

async function fetchDirectPaidEvents(userId) {
    const options = {
        method: 'POST',
        url: graphURL,
        headers: {
            'content-type': 'application/json'
        },
        data: {
            query: `{
            directPaidEvents(where: { to: "${userId}" }) {
                id
                amount
                from
                level
                transactionHash
                blockTimestamp
            }
            }`
        }
    };

    const response = await axios.request(options);
    const res = response.data; // Response received from the API
    
    return res.data.directPaidEvents;  
}

export default fetchDirectPaidEvents;