import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import useIsWindowVisible from '../../hooks/useIsWindowVisible';
import { simpleRpcProvider } from '../../utils/providers';
import { setBlock } from '.';

export const usePollBlockNumber = (refreshTime = 12000) => {
    const timer = useRef(null);
    const dispatch = useAppDispatch();
    const isWindowVisible = useIsWindowVisible();
    useEffect(() => {
        if (isWindowVisible) {
            timer.current = setInterval(async () => {
                try{
                    const blockNumber = await simpleRpcProvider.getBlockNumber();
                    dispatch(setBlock(blockNumber));
                }catch (e) {
                    console.log("Block Header Error")
                }
            }, refreshTime);
        }
        else {
            clearInterval(timer.current);
        }
        return () => clearInterval(timer.current);
    }, [dispatch, timer, isWindowVisible, refreshTime]);
};
export const useBlock = () => useSelector((state) => state.block);
export const useInitialBlock = () => useSelector((state) => state.block.initialBlock);